<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>
            Name: {{ worksheet.user.first_name }} {{ worksheet.user.last_name }} ||
            View worksheet: {{ worksheet.title }}
            <button
              v-if="worksheet.status === 1"
              class="button float-right marg-left"
              v-on:click="deleteWorksheet">
              Delete Worksheet
            </button>
            <a
              target="_blank"
              class="button float-right"
              v-bind:href="worksheet.worksheet_url">
                Download worksheet
            </a>
          </h1>
          <div class="grid-x pad-top--small" style="clear:both">
            <div class="cell small-3">
              <p>
                <span
                  v-if="worksheet.status === 1"
                  class="indicator new">
                  &nbsp;
                </span>
                <span
                  v-if="worksheet.status === 2"
                  class="indicator pending">
                  &nbsp;
                </span>
                <span
                  v-if="worksheet.status === 3"
                  class="indicator passed">
                  &nbsp;
                </span>
                <span
                  v-if="worksheet.status === 4"
                  class="indicator referred">
                  &nbsp;
                </span>
              </p>
            </div>
            <div
              v-if="userLevel === 1 || userLevel === '1' || userLevel === 2 || userLevel === '2'"
              class="cell small-9 medium-3">
              <form v-on:submit="updateStatus">
                <label>Change status</label>
                <select v-model="worksheet.status">
                  <option value="1">New</option>
                  <option value="2">Pending</option>
                  <option value="3">Passed</option>
                  <option value="4">Referred</option>
                </select>
                <button class="button">Save</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Notes:</h3>
          <div v-html="worksheet.notes"></div>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Comments</h3>
          <ul class="comments">
            <li v-for="(comment, index) in worksheet.comments" v-bind:key="index">
              {{ comment.user.first_name }} {{ comment.user.last_name }} commented on
              {{ comment.created | moment("dddd, MMMM Do YYYY") }}
              <div v-html="comment.comment"></div>
              <div v-if="comment.file_location">
                <a
                  target="_blank"
                  class="button"
                  v-bind:href="comment.file_location">
                  Download file
                </a>
              </div>
              <button
                v-if="comment.comment_read === false"
                @click="markCommentRead(comment.id)"
                class="button">
                Mark as Read
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Add a new comment</h3>
          <form class="file-form">
            <vue-editor
              id="notesEditor"
              v-bind:editorToolbar="customToolbar"
              v-model="comment.comment"
              placeholder="Notes text"
              rows="12">
            </vue-editor>
            <br />
            <label>File</label>
            <label>Click the button below to select a file to upload</label>
            <div class="file"></div>
            <div><p>{{ filename }}</p></div>
            <button class="button">Save comment</button>
            <div class="UppyProgressBar"></div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="clearMessage">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

const Uppy = require('@uppy/core');
const UppyForm = require('@uppy/form');
const Transloadit = require('@uppy/transloadit');
const ProgressBar = require('@uppy/progress-bar');
const FileInput = require('@uppy/file-input');

export default {
  name: 'ViewWorksheet',
  data() {
    return {
      worksheet: {
        user: {
          first_name: '',
          last_name: '',
        },
      },
      comment: {},
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: '1' }, { header: '2' }],
        ['link'],
      ],
      message: '',
      filename: '',
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    userLevel() {
      return this.$store.getters.userLevel;
    },
    userName() {
      return this.$store.state.currentUserName;
    },
  },
  methods: {
    markCommentRead(commentId) {
      axios.get(`/comments/markRead/${commentId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`);
      this.message = 'The comment has been marked read';
    },
    clearMessage() {
      this.message = '';
    },
    deleteWorksheet() {
      axios.post(`/worksheets/delete.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.worksheet)
        .then((response) => {
          if (response.data.response === true) {
            this.$router.go('-1');
          } else {
            this.message = 'The worksheet could not be deleted. Please try again.';
          }
        });
    },
    updateStatus(event) {
      event.preventDefault();
      axios.post(`/worksheets/updateStatus.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.worksheet)
        .then((response) => {
          this.worksheet = response.data.worksheet;
        });
    },
    saveComment(result) {
      this.comment.file = result;
      this.comment.parentId = this.$route.params.id;
      this.comment.model = 'Worksheets';
      axios.post(`/comments/create.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.comment)
        .then((response) => {
          this.message = 'Your comment has been saved.';
          this.worksheet.comments.push(response.data.comment);
        });
    },
    getWorksheet() {
      const worksheetId = this.$route.params.id;
      axios.get(`/worksheets/getTheWorksheet/${worksheetId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.worksheet = response.data.worksheet;
        });
    },
  },
  mounted() {
    this.getWorksheet();
    Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: (1000000 * 1000),
        maxNumberOfFiles: 3,
        minNumberOfFiles: 0,
      },
    })
      .use(UppyForm, {
        target: '.file-form',
        multipleResults: true,
        triggerUploadOnSubmit: true,
        submitOnSuccess: false,
      })
      .use(FileInput, {
        id: 'FileInput',
        target: '.file',
        pretty: true,
        inputName: 'comment',
      })
      .on('file-added', (res) => {
        this.filename = res.name;
      })
      .use(ProgressBar, {
        target: '.UppyProgressBar',
        hideAfterFinish: false,
      })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: 'ad27d2c2cad8454fba2791c04c58c00e',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
      })
      .on('complete', (result) => {
        this.saveComment(result);
      });
  },
};
</script>
